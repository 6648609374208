// src/components/ExampleComponent.js
import React from 'react';

import '../assets/css/bootstrap.css';
import '../assets/css/animate.css';
import '../assets/style.css';
import '../assets/css/responsive.css';


import $ from 'jquery';

// import '../assets/js/jquery-3.4.1.min.js'; 
// import 'https://code.jquery.com/jquery-3.7.1.min.js'; 
// import '../assets/js/bootstrap.bundle.min.js'; 
import 'https://kit.fontawesome.com/166a09d3e4.js'; 

// import '../assets/js/wow.min.js'; 
// import '../assets/js/custom.js'; 
// import '../assets/js/popper.js'; 


 import { useNavigate } from 'react-router-dom';



const ContentPage = () => {

   const navigate = useNavigate();

  const handleNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

 // const divStyle = {
 //            marginTop: '-280px'
 //  };

  return (
    <div>
 

    <header className="header-area">
        <div className="container">
            <nav className="navbar navbar-expand-lg main-nav-area wow fadeInUp ">
                <a href="/" onClick={(e) => handleNavigation(e, '/')}>
            <img src="../assets/images/logo.png" className="nav-logo" alt=""/>
          </a>
                <div className="top-menu ms-auto d-flex align-items-center">
                    <ul className="d-flex align-items-center">
                        <li className="d-none d-lg-block"><a href="#">Impressum</a></li>
                        <li className="d-none d-lg-block"><a href="#">Datenschutz</a></li>
                        <li className="nav-item button-effect"><a className="nav-link btn contact-btn" href="/contact" onClick={(e) => handleNavigation(e, '/contact')}><i className="fa-solid fa-phone-volume"></i> Kontakt</a></li>
                    </ul>
                    <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"><i className="fa-solid fa-bars"></i></button>
                </div>
                <div className="collapse navbar-collapse" id="btnav-01">
                    <ul className="navbar-nav">
                        <li className="nav-item"><a className="nav-link" href="/" onClick={(e) => handleNavigation(e, '/')}>Startseite</a></li>
                        <li className="nav-item"><a className="nav-link" href="/content" onClick={(e) => handleNavigation(e, '/content')}>Content</a></li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Leistungen
                  </a>
                            <div className="dropdown-menu" aria-labelledby="btnav1-dd">
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                            </div>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="#">Ansprechpartner</a></li>
                        <li className="nav-item"><a className="nav-link" href="/contact" onClick={(e) => handleNavigation(e, '/contact')}>Kontakt</a></li>
                    </ul>
                    <ul className=" social-icon ms-auto" hidden>
                        <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                    </ul>
                </div>
            </nav>


            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><img src="../assets/images/logo.png" alt=""/></h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="mobile-menu">
                    <ul className="navbar-nav">
                        <li className="nav-item"><a className="nav-link" href="/" onClick={(e) => handleNavigation(e, '/')}>Startseite</a></li>
                        <li className="nav-item"><a className="nav-link" href="/content" onClick={(e) => handleNavigation(e, '/content')}>Content</a></li>
                        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Leistungen
                  </a>
                            <div className="dropdown-menu" aria-labelledby="btnav1-dd">
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                                <a className="dropdown-item" href="#">submenu item</a>
                            </div>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="#">Ansprechpartner</a></li>
                        <li className="nav-item"><a className="nav-link" href="/contact" onClick={(e) => handleNavigation(e, '/contact')}>Kontakt</a></li>
                    </ul>
                    <ul className=" social-icon ms-auto" hidden>
                        <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
         

             <div className="hero-area">
            <div className="row g-0">
                <div className="col-lg-4 col-md-5 order-2 order-md-1 order-lg-1">
                    <div className="hero-content wow fadeInUp button-effect">
                        
                    </div>
                </div>
                <div className="col-lg-8 col-md-7 my-0 my-md-auto order-1 order-lg-2 order-md-2">
                    <div className="hero-images">
                        
                    </div>
                </div>
            </div>
        </div>

                <h2 className='big-font'>Herzlich willkommen </h2>



         <div className="service-area">
            <div className="container">
                <div className="service-block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                        <img className="wow fadeInUp" src="../assets/images/hero_cars.png" alt=""/>
                           
                        </div>
                        <div className="col-lg-6 col-md-6 text-left">
                            <h2>Service</h2>
                            <br/>
                            <p>Lorem ipsum dolor sit amet consectetur. Libero semper lorem vestibulum laoreet vel eget vel purus. Maecenas phasellus sem montes at amet. Nec aliquam volutpat.
                            <br/>
                            <br/>
                            Lorem ipsum dolor sit amet consectetur. Libero semper lorem vestibulum laoreet vel eget vel purus. Maecenas phasellus sem montes at amet. Nec aliquam volutpat.
                            </p>
                             <div className="contact-button button-effect wow fadeInUp">
                            <a className=" contact-btn wow fadeInUp" href="/contact" onClick={(e) => handleNavigation(e, '/contact')}><i className="fa-solid fa-phone-volume"></i> Kontakt</a>
                        </div>
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </div>
      
    </header>
 
   <section className="lr-section" >
        <div className="bg-oversly">
            <div className="container">
<div className="service-block">
                    <div className="row">

                      <div className="col-lg-6 col-md-6 text-left">
                            <h2>Service</h2>
                            <br/>
                            <p>Lorem ipsum dolor sit amet consectetur. Libero semper lorem vestibulum laoreet vel eget vel purus. Maecenas phasellus sem montes at amet. Nec aliquam volutpat.
                            <br/>
                            <br/>
                            Lorem ipsum dolor sit amet consectetur. Libero semper lorem vestibulum laoreet vel eget vel purus. Maecenas phasellus sem montes at amet. Nec aliquam volutpat.
                            </p>
                             <div className="contact-button button-effect wow fadeInUp">
                            <a className=" contact-btn wow fadeInUp" href="/contact" onClick={(e) => handleNavigation(e, '/contact')}><i className="fa-solid fa-phone-volume"></i> Kontakt</a>
                        </div>
                        </div>


                        <div className="col-lg-6 col-md-12">
                        <img className="wow fadeInUp" src="../assets/images/hero_cars.png" alt=""/>
                           
                        </div>
                      
                        
                       
                    </div>
                </div>
            </div>
            </div>
            </section>



    <footer className="footer-area">
        <div className="container">
            <div className="footer-top">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="footer-logo wow fadeInUp">
                            <a href="#"><img src="../assets/images/logo.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="contact-info d-flex align-items-center justify-content-between">
                            <div className="contact-item d-flex align-items-center wow fadeInUp">
                                <div className="cnt-icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <a href="callto:(+22) 123 - 4567 - 900"> (+22) 123 - 4567 - 900</a>
                            </div>
                            <div className="contact-item d-flex align-items-center wow fadeInUp mt-3 mt-md-0 mt-lg-0">
                                <div className="cnt-icon">
                                    <i className="fa-regular fa-envelope"></i>
                                </div>
                                <a href="mailto:support@doctorate.com">support@doctorate.com</a>
                            </div>
                            <div className="contact-item social-list wow fadeInUp d-flex align-items-center">
                                <ul className=" social-icon" hidden>
                                    <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                                    <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content">
                <div className="row">
                    <div className="col-lg-5 col-md-4">
                        <div className="footer-inner-box wow fadeInUp">
                            <p className="wow fadeInUp">Lorem ipsum dolor sit amet consectetur. Libero semper lorem vestibulum laoreet vel eget vel purus. Maecenas phasellus sem montes at amet. Nec aliquam volutpat.</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="footer-link d-flex align-items-center">
                                    <div className="explor-links">
                                        <h3 className="wow fadeInUp">Explore</h3>
                                        <ul>
                                            <li className="wow fadeInUp"><a href="#">Startseite</a></li>
                                            <li className="wow fadeInUp"><a href="#">Ansprechpartner</a></li>
                                            <li className="wow fadeInUp"><a href="/content" onClick={(e) => handleNavigation(e, '/content')}>Content</a></li>

                                            <li className="wow fadeInUp"><a href="/contact" onClick={(e) => handleNavigation(e, '/contact')}>Kontakt</a></li>
                                             <li hidden className="wow fadeInUp"><a href="#">Testimonials</a></li>
                                            <li hidden className="wow fadeInUp"><a href="#">News</a></li>
                                        </ul>
                                    </div>
                                    <div className="explor-links" hidden>
                                        <h3 className="wow fadeInUp">Uitility Pages</h3>
                                        <ul>
                                            <li className="wow fadeInUp"><a href="#">Style Guide</a></li>
                                            <li className="wow fadeInUp"><a href="#">Changelog</a></li>
                                            <li className="wow fadeInUp"><a href="#">Licenses</a></li>
                                            <li className="wow fadeInUp"><a href="#">Protected Page</a></li>
                                            <li className="wow fadeInUp"><a href="#">404 Page</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="book-appointment button-effect wow fadeInUp">
                                    <h3 className="wow fadeInUp">Book an appointment</h3>
                                    <p className="wow fadeInUp">It is a long established fact that a reader will be distracted</p>
                                    <form action="">
                                        <input type="email" placeholder="your email address" />
                                        <button type="submit">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <a href="#" className="scrolltotop"><i className="fas fa-angle-up"></i></a>


    </div>
  );
};

export default ContentPage;
