import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import IndexComponent from './components/IndexComponent';
import ContactComponent from './components/ContactComponent';
import ContentPage from './components/ContentPage';

function App() {
  return (
    <Router>
      <div className="App">
        
        <main>
          <Routes>
            <Route path="/" element={<IndexComponent />} />
            <Route path="/contact" element={<ContactComponent />} />
            <Route path="/content" element={<ContentPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
